import cls from 'classnames';
import DecreaseQuantityIcon from 'Components/Icons/DecreaseQuantityIcon';
import IncreaseQuantityIcon from 'Components/Icons/IncreaseQuantityIcon';
import { useMemo } from 'react';
import useCartStore from 'Support/Stores/useCartStore';

const QuantityField = ({ className, inline, editable, noToZero = false, product }) => {
  const { cart, updateQuantity } = useCartStore();
  const quantityInCart = parseInt(cart[product.id]?.quantity_in_cart || 0);
  const disableDecrease = useMemo(() => quantityInCart === 0 || (noToZero && quantityInCart === 1), [quantityInCart]);
  const disableIncrease = useMemo(() => false, [quantityInCart]);

  const update = (quantity) => {
    if (parseInt(quantity) !== parseInt(quantityInCart)) {
      updateQuantity(product, quantity);
    }
  };

  return (
    <div className={cls('items-center justify-between gap-1 sm:gap-2', inline ? 'inline-flex' : 'flex', className)}>
      <button onClick={() => update(quantityInCart - 1)} disabled={disableDecrease}>
        <DecreaseQuantityIcon disabled={disableDecrease} />
      </button>

      {editable ? (
        <input
          className={cls(
            'w-8 grow rounded-lg border border-gray-400 px-2 py-3 text-center font-normal focus:ring-primary sm:w-16',
            '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
          )}
          onChange={(event) => update(event.target.value)}
          style={{ boxShadow: '0px 1.5px 4px -1px #0A090B12' }}
          type="number"
          value={quantityInCart}
        />
      ) : (
        <div className="w-10 text-center">{quantityInCart}</div>
      )}

      <button onClick={() => update(quantityInCart + 1)} disabled={disableIncrease}>
        <IncreaseQuantityIcon disabled={disableIncrease} />
      </button>
    </div>
  );
};

export default QuantityField;
