import { Link } from '@inertiajs/react';
import cls from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import getCatalogueImage from 'Support/getCatalogueImage';
import useCategoryColor from 'Support/Hooks/useCategoryColor';
import route from 'ziggy-js';

const ProductsMenu = forwardRef(({ catalogues, menuOpen, setMenuOpen }, ref) => {
  const [activeCatalogue, setActiveCatalogue] = useState(catalogues[0]);
  const [categoryImage, setCategoryImage] = useState();

  useEffect(() => {
    setCategoryImage(getCatalogueImage(activeCatalogue));
  }, [activeCatalogue.id]);

  return (
    <div
      className={cls(
        'absolute z-40 flex w-192 max-w-full -translate-x-1/2 overflow-hidden rounded-b-lg border-t bg-white shadow-menu @4xl:mt-[14px]',
        !menuOpen && 'hidden',
      )}
      ref={ref}
      style={{ left: '50%' }}
    >
      <div className="w-48 bg-gray-200 pb-4 text-sm font-medium text-gray-600">
        <div className="px-4 pb-2 pt-4 text-xxs font-semibold uppercase text-primary">Categories</div>
        {catalogues.map((catalogue, index) => (
          <div
            key={index}
            onClick={() => setActiveCatalogue(catalogue)}
            className={cls('cursor-pointer px-4 py-2', catalogue.id === activeCatalogue.id && 'bg-white')}
          >
            {catalogue.name}
          </div>
        ))}
      </div>

      <div className="pb-4 text-sm font-medium">
        <div className="pb-2 pl-6 pt-4 text-xxs font-semibold uppercase text-primary">Product Type</div>
        <div className="grid grid-cols-3 pl-6 pr-4 text-gray-700">
          {catalogues?.length && (
            <Link
              onClick={() => setMenuOpen(false)}
              href={
                activeCatalogue.id === 0 ? route('browse.catalogue.sustainable') : route('browse.catalogue', { indexCatalogue: activeCatalogue.id })
              }
              className="flex w-48 items-center gap-2 py-2"
            >
              <div
                className="h-8 w-8 rounded border bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundColor: useCategoryColor(activeCatalogue.name),
                  backgroundImage: `url("${categoryImage}")`,
                  borderColor: useCategoryColor(activeCatalogue.name),
                }}
              />
              All products
            </Link>
          )}

          {activeCatalogue?.categories.map((category, index) => (
            <Link
              className="flex items-center gap-2 py-2"
              href={
                activeCatalogue.id === 0
                  ? route('browse.catalogue.sustainable', { indexCatalogue: category.id })
                  : route('browse.catalogue', { indexCatalogue: category.catalogue_id, indexCategory: category.id })
              }
              key={index}
              onClick={() => setMenuOpen(false)}
            >
              <div
                className="h-8 w-8 rounded border bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundColor: category.image ? null : useCategoryColor(activeCatalogue.id === 0 ? category.name : activeCatalogue.name),
                  backgroundImage: 'url("' + (activeCatalogue.id === 0 ? getCatalogueImage(category) : category.image || categoryImage) + '")',
                  borderColor: useCategoryColor(activeCatalogue.id === 0 ? category.name : activeCatalogue.name),
                }}
              />
              {category.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
});

export default ProductsMenu;
