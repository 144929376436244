const TogglePriceIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.72055 15.0955C1.96789 12.2471 1.98654 7.70666 4.79029 4.88103C6.54567 3.11186 8.98292 2.42917 11.2645 2.83781"
      stroke="#949CA6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2794 5.00977C18.032 7.85811 18.0134 12.3986 15.2096 15.2242C13.4543 16.9934 11.0169 17.6761 8.73535 17.2674"
      stroke="#949CA6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5054 7.71484H9.33091C8.68466 7.71484 8.16089 8.23862 8.16089 8.88567C8.16089 9.53184 8.68466 10.0564 9.33091 10.0564H10.6695C11.3157 10.0564 11.8403 10.5803 11.8403 11.2273C11.8403 11.8734 11.3157 12.398 10.6695 12.398H8.49491"
      stroke="#949CA6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.5 14.9202L4.73538 15.3645L5.17726 13.1412" stroke="#949CA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.4998 5.18394L15.2644 4.73962L14.8225 6.96284" stroke="#949CA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 12.396V13.3788M10 6.72522V7.71683" stroke="#949CA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default TogglePriceIcon;
