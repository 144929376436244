import { useEffect } from 'react';

const useCloseOnOutsideClick = (ref, setter) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // Clicked outside the component, close the menu
        setter(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('click', handleOutsideClick);

    // Remove event listener when the component unmounts
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);
};

export default useCloseOnOutsideClick;
