import { Link } from '@inertiajs/react';
import { mdiLoading } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '@webfox/webfox-ui';
import cls from 'classnames';
import React, { forwardRef } from 'react';

export const SecondaryButton = forwardRef(({ children, className = '', iconEnd, iconStart, isLoading = false, title, ...extendedProps }, ref) => {
  const baseClasses = cls(
    'inline-flex gap-2 items-center justify-center rounded-full border border-gray-400 px-4 py-2 text-sm font-semibold text-gray-800',
    'focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
  );
  let renderedElement;

  if (!className?.includes('h-')) {
    className += ' h-11';
  }

  if (!className?.includes('w-')) {
    className += ' sm:w-auto';
  }

  if (!className?.includes('px-')) {
    className += ' px-6';
  }

  if (!className?.includes('bg-')) {
    className = cls(className, `bg-white`);
  }

  if (React.isValidElement(children) && children.type === Link) {
    renderedElement = React.cloneElement(children, {
      ...extendedProps,
      ...children.props,
      className: cls(baseClasses, className),
    });
  } else {
    renderedElement = (
      <button className={cls(baseClasses, className)} {...extendedProps} ref={ref}>
        {iconStart}
        {children}
        {iconEnd}
      </button>
    );
  }

  if (isLoading) {
    renderedElement = (
      <>
        <Icon path={mdiLoading} size={0.8} className="mr-2 animate-spin" />
        {renderedElement}
      </>
    );
  }

  return title === undefined ? renderedElement : <Tooltip content={title}>{renderedElement}</Tooltip>;
});
