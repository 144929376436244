const ShoppingCartIcon = ({ size = 20, width = 1.5 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 2.62366H4.1638L6.3749 11.9837C6.58895 12.8886 7.43626 13.4984 8.36225 13.4124L14.1433 12.8765C14.9801 12.7986 15.6872 12.223 15.932 11.4186L17.4588 6.41504C17.642 5.81747 17.1912 5.21341 16.5661 5.21908L4.85786 5.31395"
      stroke="currentColor"
      strokeWidth={width}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.40558 16.9556V17.054M7.80455 16.9768C7.80455 17.1984 7.62475 17.378 7.40313 17.378C7.18151 17.378 7.00195 17.1984 7.00195 16.9768C7.00195 16.7551 7.18151 16.5754 7.40313 16.5754C7.62475 16.5754 7.80455 16.7551 7.80455 16.9768Z"
      stroke="currentColor"
      strokeWidth={width}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0813 16.9556V17.054M15.4803 16.9768C15.4803 17.1984 15.3005 17.378 15.0789 17.378C14.8573 17.378 14.6777 17.1984 14.6777 16.9768C14.6777 16.7551 14.8573 16.5754 15.0789 16.5754C15.3005 16.5754 15.4803 16.7551 15.4803 16.9768Z"
      stroke="currentColor"
      strokeWidth={width}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShoppingCartIcon;
