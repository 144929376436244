import { Link } from '@inertiajs/react';
import { mdiLoading } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '@webfox/webfox-ui';
import cls from 'classnames';
import React, { forwardRef } from 'react';

export const PrimaryButton = forwardRef(({ children, className = '', iconEnd, iconStart, isLoading = false, title, ...extendedProps }, ref) => {
  const baseClasses = cls(
    'inline-flex gap-2 items-center justify-center rounded-full border border-primary font-semibold text-white',
    'shadow-sm hover:bg-primary-highlight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
  );

  let renderedElement;

  if (!className?.includes('h-')) {
    className = cls(className, 'h-11');
  }

  if (!className?.includes('w-')) {
    className = cls(className, 'sm:w-auto');
  }

  if (!className?.includes('px-')) {
    className = cls(className, 'px-8');
  }

  if (!className?.includes('py-')) {
    className = cls(className, 'py-2');
  }

  if (!className?.includes('text-')) {
    className = cls(className, 'text-sm');
  }

  if (!className?.includes('bg-')) {
    className = cls(className, `bg-primary`);
  }

  if (React.isValidElement(children) && children.type === Link) {
    renderedElement = React.cloneElement(children, {
      ...extendedProps,
      ...children.props,
      className: cls(baseClasses, className),
    });
  } else {
    renderedElement = (
      <button className={cls(baseClasses, className)} {...extendedProps} ref={ref}>
        {iconStart}
        {children}
        {iconEnd}
      </button>
    );
  }

  if (isLoading) {
    renderedElement = (
      <>
        <Icon path={mdiLoading} size={0.8} className="mr-2 animate-spin" />
        {renderedElement}
      </>
    );
  }

  return title === undefined ? renderedElement : <Tooltip content={title}>{renderedElement}</Tooltip>;
});
