import { Link } from '@inertiajs/react';
import { mdiGhostOffOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import { PrimaryButton } from 'Components/Buttons/PrimaryButton';
import { SecondaryButton } from 'Components/Buttons/SecondaryButton';
import ChevronDownIcon from 'Components/Icons/ChevronDownIcon';
import LogoutIcon from 'Components/Icons/LogoutIcon';
import ShoppingCartIcon from 'Components/Icons/ShoppingCartIcon';
import StarIcon from 'Components/Icons/StarIcon';
import TogglePriceIcon from 'Components/Icons/TogglePriceIcon';
import UserIcon from 'Components/Icons/UserIcon';
import UsersIcon from 'Components/Icons/UsersIcon';
import MobilePopupPanel from 'Components/MobilePopupPanel';
import React, { useRef, useState } from 'react';
import useCloseOnOutsideClick from 'Support/Hooks/useCloseOnOutsideClick';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const AccountMenu = ({ setLoginOpen }) => {
  const user = useUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef();

  useCloseOnOutsideClick(ref, setMenuOpen);

  return !!user ? (
    <div className="relative" ref={ref}>
      <button className="flex items-center gap-1" onClick={() => setMenuOpen(!menuOpen)}>
        <img src={user.avatar} className="h-9 w-9 rounded-full" alt={user.name + ' avatar'} />
        <div className="hidden sm:block">
          <ChevronDownIcon />
        </div>
      </button>

      <div className="hidden sm:block">
        <div
          className={cls('absolute right-0 z-50 flex flex-col divide-y rounded-lg bg-white text-sm text-gray-700 shadow-soft', !menuOpen && 'hidden')}
        >
          <Link href={route('browse.catalogue.toggle-price-filter')} className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black">
            <TogglePriceIcon />
            Toggle prices
          </Link>
          <Link href={route('account.index')} className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black">
            <UserIcon />
            Account Details
          </Link>
          <Link href={route('account.order-history')} className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black">
            <div className="text-gray-500">
              <ShoppingCartIcon />
            </div>
            Orders
          </Link>
          <Link href={route('browse.catalogue.favourites')} className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black">
            <StarIcon />
            My Favourites
          </Link>
          {user?.is_client_manager && (
            <Link href={route('users.index')} className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black">
              <UsersIcon />
              Users
            </Link>
          )}
          {user?.is_ghosting ? (
            <a href={route('auth.ghost.stop')} className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black">
              <Icon path={mdiGhostOffOutline} size={0.75} className="text-[#949CA6]" />
              Stop ghosting
            </a>
          ) : (
            <Link href={route('logout')} className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black">
              <LogoutIcon />
              Sign out
            </Link>
          )}
        </div>
      </div>
      <div className="sm:hidden">
        <MobilePopupPanel open={menuOpen} setOpen={() => setMenuOpen(false)} className="divide-y p-4">
          <Link
            href={route('browse.catalogue.toggle-price-filter')}
            className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black"
            onClick={() => setMenuOpen(false)}
          >
            <TogglePriceIcon />
            Toggle prices
          </Link>
          <Link
            href={route('account.index')}
            className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black"
            onClick={() => setMenuOpen(false)}
          >
            <UserIcon />
            Account Details
          </Link>
          <Link
            href={route('account.order-history')}
            className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black"
            onClick={() => setMenuOpen(false)}
          >
            <div className="text-gray-500">
              <ShoppingCartIcon />
            </div>
            Orders
          </Link>
          <Link
            href={route('browse.catalogue.favourites')}
            className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black"
            onClick={() => setMenuOpen(false)}
          >
            <StarIcon />
            My Favourites
          </Link>
          {user?.is_client_manager && (
            <Link
              href={route('users.index')}
              className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black"
              onClick={() => setMenuOpen(false)}
            >
              <UsersIcon />
              Users
            </Link>
          )}
          {user?.is_ghosting ? (
            <Link
              as="a"
              href={route('auth.ghost.stop')}
              className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black"
              onClick={() => setMenuOpen(false)}
            >
              <Icon path={mdiGhostOffOutline} size={0.75} className="text-[#949CA6]" />
              Stop ghosting
            </Link>
          ) : (
            <Link
              href={route('logout')}
              className="flex items-center gap-2 whitespace-nowrap px-3 py-2 hover:text-black"
              onClick={() => setMenuOpen(false)}
            >
              <LogoutIcon />
              Sign out
            </Link>
          )}
        </MobilePopupPanel>
      </div>
    </div>
  ) : (
    <>
      <SecondaryButton className="px-4 text-xs sm:hidden sm:px-8 sm:text-sm" onClick={() => setLoginOpen(true)}>
        Log in
      </SecondaryButton>
      <PrimaryButton className="hidden px-4 text-xs sm:block sm:px-8 sm:text-sm" onClick={() => setLoginOpen(true)}>
        Log in
      </PrimaryButton>
    </>
  );
};

export default AccountMenu;
