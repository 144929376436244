const SearchIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2276 24.2143C20.0192 24.2143 24.7143 19.5192 24.7143 13.7276C24.7143 7.936 20.0192 3.24097 14.2276 3.24097C8.436 3.24097 3.74097 7.936 3.74097 13.7276C3.74097 19.5192 8.436 24.2143 14.2276 24.2143Z"
      stroke="#3C414C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.5212 21.5659L25.6326 25.6666" stroke="#3C414C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SearchIcon;
