import { router } from '@inertiajs/react';
import { PrimaryButton } from 'Components/Buttons/PrimaryButton';
import { SecondaryButton } from 'Components/Buttons/SecondaryButton';
import React, { useRef, useState } from 'react';
import route from 'ziggy-js';

const CommentField = ({ product, setAddingComment }) => {
  const [value, setValue] = useState(product.comments_in_cart || '');
  const inputRef = useRef();

  const update = () => {
    setValue(inputRef.current.value);

    router.put(route('cart.comments', product.id), { comments: inputRef.current.value }, { preserveScroll: true });

    setAddingComment(false);
  };

  return (
    <div className="grid gap-2">
      <div className="text-sm font-medium">Add a note</div>
      <textarea
        className="rounded-xl border-gray-400 placeholder:text-base placeholder:text-gray-400"
        defaultValue={value}
        name="note"
        placeholder="Enter..."
        ref={inputRef}
      />
      <div className="grid grid-cols-2 gap-2">
        <SecondaryButton onClick={() => setAddingComment(false)}>Cancel</SecondaryButton>
        <PrimaryButton onClick={update}>Save</PrimaryButton>
      </div>
    </div>
  );
};

export default CommentField;
