const useCategoryColor = (name) => {
  switch (name) {
    case 'Aquatic':
      return '#0b84ac';
    case 'Bird':
      return '#53c1d0';
    case 'Cat':
      return '#e797ab';
    case 'Dog':
      return '#73d3ec';
    case 'Reptile':
      return '#51aade';
    case 'Small Animal':
      return '#92bbca';
    case 'Sustainable Products':
      return '#97b97d';
    default:
      return '#009abc';
  }
};

export default useCategoryColor;
