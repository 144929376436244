import { FieldContext } from '@webfox/webfox-ui';
import { ErrorMessage } from 'formik';
import React from 'react';

export const CheckboxFieldWrapper = ({ label, name, children }) => (
  <>
    <FieldContext.Provider value={{ name }}>
      <label className="flex items-center space-x-3">
        {children}
        <div className="text-sm font-medium text-black">{label}</div>
      </label>
    </FieldContext.Provider>

    <ErrorMessage name={name} component="div" className="mt-2 text-sm text-red-600" />
  </>
);
