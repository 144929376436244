const DecreaseQuantityIcon = ({ disabled }) => {
  const strokeColor = disabled ? '#a2aeb4' : '#009ABC';
  const fillColor = disabled ? '#fff' : '#009ABC';
  const iconColor = disabled ? '#a2aeb4' : '#fff';

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C16.9709 3 21 7.02908 21 12C21 16.9699 16.9709 21 12 21C7.02908 21 3 16.9699 3 12C3 7.02908 7.02908 3 12 3Z"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.2989 12.002H8.69922" stroke={iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DecreaseQuantityIcon;
