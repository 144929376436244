const UserIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6253 10C17.6253 5.85757 14.2677 2.5 10.1253 2.5C5.98281 2.5 2.62524 5.85757 2.62524 10C2.62524 14.1416 5.98281 17.5 10.1253 17.5C14.2677 17.5 17.6253 14.1416 17.6253 10Z"
      stroke="#949CA6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83496 16.1453C6.00524 14.8237 7.18091 13.3561 10.0998 13.3561C13.0512 13.3561 14.2188 14.8318 14.3809 16.1697"
      stroke="#949CA6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8485 8.60558C12.8485 10.1226 11.6192 11.3518 10.1022 11.3518C8.58525 11.3518 7.35522 10.1226 7.35522 8.60558C7.35522 7.08857 8.58525 5.85938 10.1022 5.85938C11.6192 5.85938 12.8485 7.08857 12.8485 8.60558Z"
      stroke="#949CA6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserIcon;
