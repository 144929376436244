import { axios } from '@webfox/webfox-ui';
import route from 'ziggy-js';
import { create } from 'zustand';

const arrayToObject = (array = []) => {
  const result = {};
  array.forEach((obj) => {
    result[obj.id] = obj;
  });
  return result;
};

const calculateItemCount = (cart) => Object.values(cart).reduce((total, item) => parseInt(total) + parseInt(item?.quantity_in_cart || 0), 0);

const useCartStore = create((set) => ({
  cart: {},
  itemCount: 0,
  addItems: (items) => {
    set(() => ({ cart: arrayToObject(items) }));
    set((state) => ({
      ...state,
      itemCount: calculateItemCount(state.cart),
    }));
  },
  removeItem: (item) => {
    set((state) => {
      axios.put(route('cart.quantity', { product: item.id, quantity: 0 }));
      const newState = { ...state };
      delete newState.cart[item.id];
      return newState || {};
    });
    set((state) => ({
      ...state,
      itemCount: calculateItemCount(state.cart),
    }));
  },
  updateQuantity: (item, quantity) => {
    quantity = parseInt(quantity);
    if (quantity === 0) {
      set((state) => {
        axios.put(route('cart.quantity', { product: item.id, quantity: 0 }));
        const newState = { ...state };
        delete newState.cart[item.id];
        return newState || {};
      });
    } else {
      axios.put(route('cart.quantity', { product: item.id, quantity: quantity }));
      set((state) => ({ cart: { ...state.cart, [item.id]: { ...item, quantity_in_cart: quantity } } }));
    }
    set((state) => ({
      ...state,
      itemCount: calculateItemCount(state.cart),
    }));
  },
}));

export default useCartStore;
