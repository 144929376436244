import { Portal } from '@headlessui/react';
import cls from 'classnames';
import Mask from 'Layouts/_partials/Mask';
import React, { useRef } from 'react';

const MobilePopupPanel = ({ children, className, open, setOpen }) => {
  const containerRef = useRef(null);

  const handleTouchStart = (e) => {
    containerRef.current.dataset.touchY = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    const touchY = e.touches[0].clientY;
    const startY = parseInt(containerRef.current.dataset.touchY, 10);
    const deltaY = touchY - startY;

    if (deltaY > 50) {
      setOpen(false);
    }
  };

  return (
    <Portal>
      <Mask setShow={setOpen} show={open} />
      <div
        className={cls(
          'fixed bottom-0 left-0 right-0 rounded-t-3xl bg-white text-lg transition-all duration-500 sm:hidden',
          open ? 'div z-50 opacity-100' : 'z-0 translate-y-full opacity-0',
          className,
        )}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        ref={containerRef}
      >
        {children}
      </div>
    </Portal>
  );
};

export default MobilePopupPanel;
