import { FieldContext } from '@webfox/webfox-ui';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';

export const CleanFieldWrapper = ({ children, ignoreMissing = false, name }) => {
  const { initialValues } = useContext(FormikContext);

  if (!ignoreMissing && !(name in initialValues)) {
    throw new Error(`Field '${name}' not registered in formik initial values`);
  }

  return <FieldContext.Provider value={{ name }}>{children}</FieldContext.Provider>;
};
