import cls from 'classnames';
import { forwardRef } from 'react';

const PageWidthConstraint = forwardRef(({ children, className, style }, ref) => (
  <div className={cls('w-full max-w-[1550px] px-4 md:px-8 xl:px-24', className)} ref={ref} style={style}>
    {children}
  </div>
));

export default PageWidthConstraint;
