const LogoutIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5134 6.1579V5.3804C12.5134 3.68457 11.1384 2.30957 9.44256 2.30957H5.38006C3.68506 2.30957 2.31006 3.68457 2.31006 5.3804V14.6554C2.31006 16.3512 3.68506 17.7262 5.38006 17.7262H9.45089C11.1417 17.7262 12.5134 16.3554 12.5134 14.6646V13.8787"
      stroke="#949CA6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18.1745 10.0178H8.14038" stroke="#949CA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.7344 7.58862L18.1744 10.0178L15.7344 12.4478" stroke="#949CA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LogoutIcon;
