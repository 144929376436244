import { router, usePage } from '@inertiajs/react';
import cls from 'classnames';
import { PrimaryButton } from 'Components/Buttons/PrimaryButton';
import { SecondaryButton } from 'Components/Buttons/SecondaryButton';
import Cart from 'Components/PageComponents/Cart';
import Footer from 'Layouts/_partials/Footer';
import Header from 'Layouts/_partials/Header';
import Mask from 'Layouts/_partials/Mask';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { CartContext } from 'Support/Contexts/CartContext';
import useCartStore from 'Support/Stores/useCartStore';
import route from 'ziggy-js';

const MainLayout = ({ children }) => {
  const { cart, catalogues, lastCartUpdate, user } = usePage().props.application;

  const [cartCollapsed, setCartCollapsed] = useState(true);
  const [showCart, setShowCart] = useState(true);
  const [surveyPopupOpen, setSurveyPopupOpen] = useState(true);
  const { addItems } = useCartStore();

  useEffect(() => {
    addItems(cart);
  }, [lastCartUpdate]);

  return (
    <CartContext.Provider value={{ cartCollapsed, setCartCollapsed, showCart, setShowCart }}>
      <div className="flex justify-center font-sans font-medium text-black">
        <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="colored" position="bottom-right" />

        <div className="w-full">
          <Header catalogues={catalogues} />

          <div className="flex w-full flex-col items-center">{children}</div>

          <Footer catalogues={catalogues} />

          {user?.surveyPopUp && user.surveyPopUp.available && (
            <>
              <Mask setShow={() => {}} show={surveyPopupOpen} />
              <div className={cls('fixed bottom-0 left-0 right-0 z-50 flex items-center sm:inset-0 sm:justify-center', !surveyPopupOpen && 'hidden')}>
                <div className={cls('rounded-b-0 z-50 w-full max-w-192 space-y-4 rounded-t-3xl bg-white p-8 sm:w-auto sm:rounded-b-3xl')}>
                  <div className="font-semibold">{user.surveyPopUp.title}</div>

                  <div>{user.surveyPopUp.message}</div>

                  <div className="flex justify-between">
                    <SecondaryButton onClick={() => router.post(route('survey-pop-up'), { action: 'no' })}>No Thank you</SecondaryButton>

                    <PrimaryButton onClick={() => router.post(route('survey-pop-up'), { action: 'yes' })}>Yes</PrimaryButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <Cart />
      </div>
    </CartContext.Provider>
  );
};

export default MainLayout;
