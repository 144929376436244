import cls from 'classnames';
import React from 'react';

const Mask = ({ className = 'sm:hidden', setShow, show }) => (
  <div
    className={cls(
      'fixed inset-0 z-40 flex items-center justify-center bg-black/50 transition-all duration-500',
      className,
      !show && 'hidden bg-black/0',
    )}
    onClick={() => setShow(false)}
  />
);

export default Mask;
