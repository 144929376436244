import { Form, Formik, useInertiaForm } from '@webfox/webfox-ui';
import cls from 'classnames';
import { CleanFieldWrapper } from 'Components/Forms/CleanFieldWrapper';
import SearchBarField from 'Components/Forms/SearchBarField';
import CloseIcon from 'Components/Icons/CloseIcon';
import SearchIconInset from 'Components/Icons/SearchIconInset';
import React from 'react';
import * as Yup from 'yup';
import route from 'ziggy-js';

const SearchBar = ({ searchOpen, setSearchOpen }) => {
  const formikProps = useInertiaForm({
    defaultValues: {
      search: '',
    },
    validationSchema: Yup.object().shape({
      search: Yup.string().required('Please enter a search value'),
    }),
    submit: ({ get, reset }) => {
      reset();
      setSearchOpen(false);
      get(route('browse.catalogue.search'));
    },
  });

  return (
    <>
      {searchOpen && <div onClick={() => setSearchOpen(false)} className="fixed left-0 top-0 z-10 h-screen w-screen" />}
      <div
        className={cls(
          'absolute inset-0 z-50 flex max-w-[100vw] items-center gap-6 rounded-b-lg bg-white px-2 duration-300',
          searchOpen ? 'h-full opacity-100' : 'h-0 opacity-0',
        )}
      >
        <div className="flex grow rounded-full">
          {searchOpen && (
            <Formik {...formikProps}>
              {({ submitDisabled }) => (
                <div className="relative w-full overflow-hidden rounded-full ring-2 ring-gray-100 focus-within:ring-primary">
                  <Form noBorder className="flex grow">
                    <CleanFieldWrapper name="search">
                      <SearchBarField placeholder="Search product, code, keyword, barcode..." autoFocus />
                    </CleanFieldWrapper>

                    <button
                      type="submit"
                      className={
                        'absolute right-1 top-1/2 z-50 flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full border-gray-400 bg-primary p-1 text-white outline-0 ring-2 ring-primary focus:border-primary focus:bg-transparent focus:text-primary focus:ring-primary '
                      }
                      disabled={submitDisabled}
                    >
                      <SearchIconInset />
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          )}
        </div>

        <button onClick={() => setSearchOpen(false)} className="focus:outline-primary">
          <CloseIcon />
        </button>
      </div>
    </>
  );
};

export default SearchBar;
