const ChevronDownIcon = ({ primary }) => {
  const strokeColor = primary ? '#2e90b0' : '#3c414c';

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9584 6.52075L9.00008 11.4791L4.04175 6.52075" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChevronDownIcon;
