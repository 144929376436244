import { FieldContext, FormikContext } from '@webfox/webfox-ui';
import { Field } from 'formik';
import React, { forwardRef, useContext } from 'react';

const SearchBarField = forwardRef(({ ...props }, ref) => {
  const { name } = useContext(FieldContext);
  const { readOnly = false } = useContext(FormikContext);

  if (readOnly) {
    props.readOnly = true;
  }

  if (name === '') {
    throw new Error('Text field with missing <VerticalFieldWrapper /> or empty name attribute on VerticalFieldWrapper component');
  }

  return (
    <Field id="global-search-field" className="w-full grow border-0 font-normal placeholder:text-gray-500" innerRef={ref} name={name} {...props} />
  );
});

export default SearchBarField;
