const SearchIconInset = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.84442 14.3368C11.1539 14.3368 13.8368 11.6539 13.8368 8.34442C13.8368 5.03493 11.1539 2.35205 7.84442 2.35205C4.53493 2.35205 1.85205 5.03493 1.85205 8.34442C1.85205 11.6539 4.53493 14.3368 7.84442 14.3368Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.0122 12.8235L14.3616 15.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SearchIconInset;
