const TrashIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3054 11.1719L20.6425 20.2465C20.5055 22.1237 18.9424 23.5782 17.059 23.5782H10.5665C8.68413 23.5782 7.12003 22.1237 6.98302 20.2456L6.32007 11.1719"
      stroke="#878C99"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22.7661 7.76538H4.85913" stroke="#878C99" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.8982 7.76404L17.3533 5.08169C17.1952 4.4904 16.6587 4.07935 16.0474 4.07935H11.5828C10.9683 4.07725 10.4297 4.4883 10.2706 5.08169L9.73096 7.76404"
      stroke="#878C99"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.7559 13.5925V18.4766M15.418 13.5925V18.4766" stroke="#878C99" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default TrashIcon;
