import useUser from 'Support/Hooks/useUser';

const StockLevels = ({ product }) =>
  useUser()?.is_ghosting ? (
    <div>
      <div className="my-1 inline-block rounded border border-primary/25 bg-white px-2 py-1 text-xs font-medium text-primary">
        Stock: {product.quantity_in_stock}
      </div>
    </div>
  ) : (
    <></>
  );

export default StockLevels;
