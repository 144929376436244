import { FieldContext, FormikContext as ValidationFormikContext } from '@webfox/webfox-ui';
import cls from 'classnames';
import { ErrorMessage, FormikContext } from 'formik';
import React, { useContext } from 'react';

export const defaultLabelClasses = 'block text-sm font-medium text-black';

export const VerticalFieldWrapper = ({
  as = 'label',
  children,
  className = '',
  ignoreMissing = false,
  label,
  labelClasses = null,
  name,
  requiredStar = null,
}) => {
  const { validationSchema } = useContext(ValidationFormikContext);
  const { initialValues, readOnly = false } = useContext(FormikContext);
  const WrapperElement = as;
  const LabelElement = as === 'label' ? 'div' : 'label';

  if (!ignoreMissing && !(name in initialValues)) {
    throw new Error(`Field '${name}' not registered in formik initial values`);
  }

  const field = validationSchema?.describe().fields[name];
  const isRequired = !readOnly && requiredStar !== false && (requiredStar === true || (field?.type !== 'boolean' && field?.optional === false));

  return (
    <WrapperElement className={cls('block space-y-1', className)}>
      {label !== false && (
        <LabelElement className={labelClasses || defaultLabelClasses} htmlFor={LabelElement === 'label' ? `field_${name}` : null}>
          {label}
          {isRequired && <span className="text-red-600"> *</span>}
        </LabelElement>
      )}

      <div>
        <FieldContext.Provider value={{ name }}>
          <div className="relative">{children}</div>
        </FieldContext.Provider>

        <ErrorMessage name={name} component="div" className="mt-2 text-sm text-red-600" />
      </div>
    </WrapperElement>
  );
};
