import { Link } from '@inertiajs/react';
import { CheckboxField, Form, Formik, PasswordField, TextField, useInertiaForm } from '@webfox/webfox-ui';
import cls from 'classnames';
import { PrimaryButton } from 'Components/Buttons/PrimaryButton';
import { CheckboxFieldWrapper } from 'Components/Forms/CheckboxFieldWrapper';
import { VerticalFieldWrapper } from 'Components/Forms/VerticalFieldWrapper';
import CloseIcon from 'Components/Icons/CloseIcon';
import Mask from 'Layouts/_partials/Mask';
import React from 'react';
import * as Yup from 'yup';
import route from 'ziggy-js';

const LoginForm = ({ show, setShow }) => {
  const formikProps = useInertiaForm({
    defaultValues: {
      username: '',
      password: '',
      remember: false,
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required('The Email/Username field is required.'),

      password: Yup.string().required('The Password field is required.'),
    }),
    submit: ({ post, reset }) =>
      post(route('login'), {
        onSuccess: () => {
          reset();
          setShow(false);
        },
      }),
  });

  return (
    <>
      <Mask setShow={setShow} show={show} />
      <div className={cls('fixed bottom-0 left-0 right-0 z-50 flex items-center sm:inset-0 sm:justify-center', !show && 'hidden')}>
        <div className="rounded-b-0 z-50 flex w-full overflow-hidden rounded-t-3xl sm:w-auto sm:rounded-b-3xl">
          <div
            className="hidden w-96 bg-gray-500 bg-cover bg-center sm:block"
            style={{ backgroundImage: 'url("/static/img/login.png")', height: '700px', width: '650px' }}
          />
          <div className="relative h-full w-full flex-col justify-between bg-white px-8 py-12 sm:h-[700px] sm:w-auto sm:py-20 lg:px-20">
            <button className="absolute right-3 top-3" onClick={() => setShow(false)}>
              <CloseIcon />
            </button>

            <div className="flex h-full w-full flex-col items-center sm:w-80">
              <img src="/static/img/logo-symbol.png" className="w-12" alt="Brooklands logo" />

              <div className="mb-2 mt-8 text-3xl font-semibold sm:text-4xl">Welcome back</div>
              <div className="mb-12 font-normal text-gray-700">Please enter your details</div>

              <div className="w-80">
                <Formik {...formikProps}>
                  {({ submitDisabled }) => (
                    <Form className="space-y-6 shadow-none">
                      <VerticalFieldWrapper name="username" label="Email/Username" requiredStar={false}>
                        <TextField type="text" placeholder="john.doe@email.com" />
                      </VerticalFieldWrapper>

                      <VerticalFieldWrapper name="password" label="Password" requiredStar={false}>
                        <PasswordField placeholder="••••••••" />
                      </VerticalFieldWrapper>

                      <div className="flex items-center justify-between">
                        <CheckboxFieldWrapper name="remember" label="Remember Me">
                          <CheckboxField />
                        </CheckboxFieldWrapper>

                        <Link href={route('forgot-password')} className="text-sm font-medium text-primary">
                          Forgot your password?
                        </Link>
                      </div>

                      <PrimaryButton className="w-full" type=" submit" disabled={submitDisabled}>
                        Login
                      </PrimaryButton>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            {/*todo: do we have sign up?*/}
            {/*<div className="text-center font-normal text-gray-800">*/}
            {/*  Don't have an account? <Link className="text-primary">Sign up</Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
