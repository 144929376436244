import { Link } from '@inertiajs/react';
import { SecondaryButton } from 'Components/Buttons/SecondaryButton';
import MobilePopupPanel from 'Components/MobilePopupPanel';
import React from 'react';
import getCatalogueImage from 'Support/getCatalogueImage';
import useCategoryColor from 'Support/Hooks/useCategoryColor';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const CategoryLink = ({ catalogue, setOpen }) => (
  <Link
    className="aspect-[4/3] h-24 rounded-xl border bg-contain bg-right-bottom bg-no-repeat px-2 py-1 text-white"
    href={catalogue.id === 0 ? route('browse.catalogue.sustainable') : route('browse.catalogue', { indexCatalogue: catalogue?.id })}
    onClick={() => setOpen(false)}
    preserveState={false}
    style={{
      backgroundColor: useCategoryColor(catalogue.name),
      backgroundImage: `url(${getCatalogueImage(catalogue)})`,
    }}
  >
    {catalogue.name}
  </Link>
);

const MobileMenu = ({ catalogues, open, setOpen }) => {
  const user = useUser();

  return (
    <MobilePopupPanel open={open} setOpen={setOpen}>
      <div className="relative mb-4 mt-8 h-24">
        <div className="scrollbar-hidden absolute left-0 right-0 flex gap-2 overflow-x-auto px-6">
          {catalogues.map((catalogue, index) => (
            <CategoryLink catalogue={catalogue} key={index} setOpen={setOpen} />
          ))}
        </div>
      </div>

      <div className="relative mb-20 h-8">
        <div className="scrollbar-hidden absolute left-0 right-0 flex gap-2 overflow-x-auto px-6">
          <SecondaryButton>
            <Link href={route('home')} onClick={() => setOpen(false)}>
              Home
            </Link>
          </SecondaryButton>

          {!!user && (
            <Link href={route('browse.catalogue.featured')} onClick={() => setOpen(false)}>
              New Products
            </Link>
          )}

          {!!user && (
            <SecondaryButton>
              <Link href={route('browse.catalogue.clearance')} onClick={() => setOpen(false)}>
                Specials
              </Link>
            </SecondaryButton>
          )}

          {!!user && (
            <SecondaryButton>
              <Link href={route('browse.catalogue.favourites')} onClick={() => setOpen(false)}>
                Favourites
              </Link>
            </SecondaryButton>
          )}

          {/*<SecondaryButton>*/}
          {/*  <Link href={route('about')} onClick={() => setOpen(false)}>*/}
          {/*    About*/}
          {/*  </Link>*/}
          {/*</SecondaryButton>*/}

          <SecondaryButton>
            <Link href={route('contact')} onClick={() => setOpen(false)}>
              Contact
            </Link>
          </SecondaryButton>
        </div>
      </div>
    </MobilePopupPanel>
  );
};

export default MobileMenu;
