const SideBarToggleIcon = ({ className }) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M14.2673 5.5H29.731C35.1358 5.5 38.5 9.31551 38.5 14.715V29.2849C38.5 34.6845 35.1358 38.5 29.7292 38.5H14.2673C8.86242 38.5 5.5 34.6845 5.5 29.2849V14.715C5.5 9.31551 8.87848 5.5 14.2673 5.5Z"
      stroke="#009ABC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.6602 5.5V38.5" stroke="#009ABC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.6897 17.2305L23.5488 22.0038L28.6897 26.7773" stroke="#009ABC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SideBarToggleIcon;
