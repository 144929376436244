import { Link, usePage } from '@inertiajs/react';
import { mdiMenu } from '@mdi/js';
import { Icon } from '@mdi/react';
import ChevronDownIcon from 'Components/Icons/ChevronDownIcon';
import LogoHeader from 'Components/Icons/LogoHeader';
import SearchIcon from 'Components/Icons/SearchIcon';
import ShoppingCartIcon from 'Components/Icons/ShoppingCartIcon';
import PageWidthConstraint from 'Components/PageWidthConstraint';
import { format } from 'date-fns';
import AccountMenu from 'Layouts/_partials/AccountMenu';
import LoginForm from 'Layouts/_partials/LoginForm';
import MobileMenu from 'Layouts/_partials/MobileMenu';
import ProductsMenu from 'Layouts/_partials/ProductsMenu';
import SearchBar from 'Layouts/_partials/SearchBar';
import React, { useContext, useRef, useState } from 'react';
import { CartContext } from 'Support/Contexts/CartContext';
import useCloseOnOutsideClick from 'Support/Hooks/useCloseOnOutsideClick';
import useUser from 'Support/Hooks/useUser';
import useCartStore from 'Support/Stores/useCartStore';
import route from 'ziggy-js';

const Header = ({ catalogues }) => {
  const user = useUser();
  const [loginOpen, setLoginOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const productMenuRef = useRef();

  useCloseOnOutsideClick(productMenuRef, setMenuOpen);

  const { setCartCollapsed } = useContext(CartContext);

  const { itemCount } = useCartStore();

  const { noSideCart } = usePage().props;

  return (
    <>
      {user?.is_trial_customer && (
        <div className="flex w-full justify-center bg-gray-700">
          <PageWidthConstraint className="flex flex-col justify-between py-4 text-sm font-normal text-gray-400 sm:flex-row sm:items-center">
            <div className="space-y-2">
              <div className="text-2xl font-semibold">You are using a trial account</div>
              <div>Some features are disabled.</div>
            </div>
            <div>Trial Expires: {format(new Date(user.trial_expires), 'MMM d, yyyy', { awareOfUnicodeTokens: true })}</div>
          </PageWidthConstraint>
        </div>
      )}

      <MobileMenu catalogues={catalogues} open={mobileMenuOpen} setOpen={setMobileMenuOpen} />

      <div className="sticky top-0 z-30 flex w-full justify-center bg-white @container">
        <PageWidthConstraint>
          <div className="flex items-center justify-between @6xl:hidden sm:relative">
            <Link href={route('home')} className="w-36 py-4 sm:w-auto">
              <LogoHeader />
            </Link>

            <div className="flex items-center gap-4 @lg:gap-8">
              <button onClick={() => setSearchOpen((open) => !open)}>
                <SearchIcon />
              </button>

              {!!user && (
                <button
                  onClick={() => {
                    setCartCollapsed((cartCollapsed) => !cartCollapsed);
                  }}
                  className="relative 2xl:hidden"
                >
                  <ShoppingCartIcon size="28" width="1" />
                  {itemCount > 0 && (
                    <div className="absolute -right-0.5 -top-0 flex h-3 w-3 items-center justify-center rounded-full border-2 border-white bg-primary text-xxs font-normal text-white" />
                  )}
                </button>
              )}

              <AccountMenu setLoginOpen={setLoginOpen} />

              <button className="sm:hidden" onClick={() => setMobileMenuOpen(true)}>
                <Icon path={mdiMenu} size={1} />
              </button>
            </div>

            <SearchBar searchOpen={searchOpen} setSearchOpen={() => setSearchOpen((open) => !open)} />
          </div>

          <div className="relative flex items-center">
            <Link href={route('home')} className="hidden shrink-0 py-4 @6xl:block">
              <LogoHeader />
            </Link>

            <div className="hidden grow justify-center sm:flex">
              <div ref={productMenuRef}>
                <button className="flex items-center gap-1 p-4" onClick={() => setMenuOpen(!menuOpen)}>
                  Products
                  <ChevronDownIcon primary />
                </button>

                <ProductsMenu catalogues={catalogues} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
              </div>

              {!!user && (
                <Link href={route('browse.catalogue.featured')} className="p-4">
                  New Products
                </Link>
              )}

              {!!user && (
                <Link href={route('browse.catalogue.clearance')} className="p-4">
                  Specials
                </Link>
              )}

              {!!user && (
                <Link href={route('browse.catalogue.favourites')} className="p-4">
                  Favourites
                </Link>
              )}

              {/*<Link href={route('about')} className="p-4">*/}
              {/*  About*/}
              {/*</Link>*/}

              <Link href={route('contact')} className="p-4">
                Contact
              </Link>

              {!!user && (user.isAdmin || user.isRep) && (
                <Link href={route('manage.dashboard')} className="p-4">
                  Manage
                </Link>
              )}
            </div>

            <div className="hidden items-center gap-8 @6xl:flex">
              {!!user && !noSideCart && (
                <button
                  onClick={() => {
                    setCartCollapsed((cartCollapsed) => !cartCollapsed);
                  }}
                  className="relative 2xl:hidden"
                >
                  <ShoppingCartIcon size="28" width="1" />

                  {itemCount > 0 && (
                    <div className="absolute -right-0.5 -top-0 flex h-3 w-3 items-center justify-center rounded-full border-2 border-white bg-primary text-xxs font-normal text-white" />
                  )}
                </button>
              )}

              <button onClick={() => setSearchOpen((open) => !open)}>
                <SearchIcon />
              </button>

              <AccountMenu setLoginOpen={setLoginOpen} />
            </div>

            <div className="hidden @6xl:block">
              <SearchBar searchOpen={searchOpen} setSearchOpen={() => setSearchOpen((open) => !open)} />
            </div>
          </div>
        </PageWidthConstraint>
      </div>

      <LoginForm show={loginOpen} setShow={setLoginOpen} />
    </>
  );
};

export default Header;
