const PencilIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2012 16.5039H16.2358" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.82138 16.6021L7.21017 16.1981C7.95663 15.9806 8.607 15.5159 9.05358 14.8806L14.3776 7.49795C15.0149 6.62352 14.8226 5.399 13.9482 4.76163L12.5036 3.70848C11.6293 3.0722 10.4037 3.26463 9.76725 4.13785L4.38486 11.6032C3.97412 12.1882 3.75708 12.8868 3.76306 13.6019L3.77641 15.0818C3.78649 16.1408 4.80473 16.8976 5.82138 16.6021Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.62622 5.73413L13.2276 9.11541" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PencilIcon;
